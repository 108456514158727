import "core-js/modules/es.array.push.js";
import { getMenuTree } from '@/api/sys-menu';
import Layout from '@/layout/index.vue';
import router, { trendsRoutes } from '@/router';
import { removeAuthorization } from '@/utils/cookies';
import { defineStore } from 'pinia';
export const usePermissionStore = defineStore('permission', {
  state: () => ({
    routes: []
  }),
  getters: {
    getRoutes(state) {
      return state.routes;
    }
  },
  actions: {
    async setRoutes() {
      await getMenuTree('Web_Vue3', 0).then(res => {
        let accessedRoutes = [];
        let dataRoutes = [];
        if (res.data.length > 0) {
          dataRoute(dataRoutes, res.data);
          localStorage.setItem('routers', JSON.stringify(dataRoutes));
          this.routes = res.data;
          // 动态路由重新配置
          formatRoute(accessedRoutes, res.data);
          accessedRoutes = accessedRoutes.concat(trendsRoutes);
          accessedRoutes.forEach(route => {
            // 动态添加路由
            router.addRoute(route);
          });
        } else {
          // 清除缓存并提示
          removeAuthorization();
          ElMessage.error('权限不足，请联系管理员！');
        }
        return accessedRoutes;
      }).catch(error => {
        // 清除缓存并提示
        removeAuthorization();
        ElMessage.error('权限异常，请联系管理员！');
        throw new Error(error);
      });
    },
    clear() {
      this.routes = [];
    }
  }
});
/**
 *
 * @param routers 返回的数据
 * @param data 数据源
 */
const formatRoute = (routers, data) => {
  if (data.length > 0) {
    data.forEach(item => {
      let flagChild = Array.isArray(item.children) && item.children.length > 0;
      let component;
      let redirect;
      // 模块路径拼接
      if (item.component.toLowerCase() === 'layout') {
        component = Layout;
        redirect = item.redirect;
      } else {
        component = () => import(`@/views${item.component}`);
        redirect = '';
      }
      // 路由值重新定义
      const menu = {
        component: component,
        path: item.path,
        name: item.name,
        meta: {
          title: item.title,
          icon: item.icon,
          type: item.type,
          hidden: item.hidden
        },
        redirect: redirect,
        children: []
      };
      // 将属于菜单的路由添加至 routers
      if (item.type < 2) {
        routers.push(menu);
        if (flagChild) {
          formatRoute(menu.children, item.children);
        }
      }
    });
  }
};
/**
 *
 * @param routers 返回的数据
 * @param data 数据源
 */
const dataRoute = (routers, data) => {
  if (data.length > 0) {
    data.forEach(item => {
      let flagChild = Array.isArray(item.children) && item.children.length > 0;
      // 路由值重新定义
      const menu = {
        title: item.title,
        path: item.path,
        redirect: item.redirect,
        type: item.type,
        children: []
      };
      // 将属于菜单的路由添加至 routers
      if (item.type < 2 && item.hidden == 0) {
        routers.push(menu);
        if (flagChild) {
          dataRoute(menu.children, item.children);
        }
      }
    });
  }
};