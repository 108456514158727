import {request, geturl} from '@/request'
import axios from "axios";
import {getAuthorization, removeAuthorization} from "@/utils/cookies";
import {ElMessage} from "element-plus";

/**
 * @Author: Djy
 * @Date: 2024-18-25 18:04:18
 * @Description: 文件上传
 */
export function fileSave(file) {
  console.log(file.get('file'))
  return axios.post(geturl() + 'oss/fileSave', file, {
    headers: {
      Authorization: getAuthorization() == null ? '' : getAuthorization(),
      'Content-Type': 'multipart/form-data',
    }
  }).then(response => {
    const res = response.data as any
    // 浏览器状态判断
    if (response.status !== 200) {
      ElMessage({
        message: res.message || 'Error',
        type: 'error',
        duration: 2 * 1000
      })
      return Promise.reject(res.message || 'Error')
    } else {
      if (res.code !== 20000) {
        // 用户登录超时
        if (res.code == -10017) {
          ElMessage.error(res.message)
          removeAuthorization()
          // 用户登陆超时刷新
          location.reload()
        }
        ElMessage.error('服务器异常！！！')
        return Promise.reject(res.message || 'Error')
      } else {
        return res
      }
    }
  }).catch(err => {
    return Promise.reject(err.message || 'Error')
  })
}

/**
 * @Author: Djy
 * @Date: 2024-18-25 18:05:23
 * @Description: 文件上传（base64）
 */
export function fileSaveBase64(form) {
  console.log('Base64 string:', form);
  return request({
    url: '/file/upload/fileSaveBase64',
    method: 'post',
    data: form
  })
}

/**
 * @Author: Djy
 * @Date: 2024-15-27 15:31:02
 * @Description: 获取oss前缀
 */
export function getOssAddress() {
  return request({
    url: '/file/upload/getOssAddress',
    method: 'post'
  })
}